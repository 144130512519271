//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState([ 'order', 'token', 'location', 'showCart' ]),
    link() {
      if(this.order && this.order.products && this.order.products.length > 0) {
        if(this.token) {
          return '/order/checkout'
        } else {
          return '/order/account'
        }
      } else if(this.location && this.order) {
        return '/order'
      } else {
        return '/'
      }
    },
    mq() {
      return this.$mq
    },
    count() {
      if (this.order && this.order.products && this.order.products.length > 0) {
        let count = 0
        for (var i = 0; i < this.order.products.length; i++) {
          if (!this.order.products[i].name.includes('Utensils')) {
            count+=this.order.products[i].quantity
          }
        }
        return count
      } else {
        return 0
      }
    },
    isCheckoutPage() {
      return this.$route.fullPath.includes('checkout')
    }
  },
  methods: {
    toggleCart() {
      if (this.isCheckoutPage) return 

      if (this.showCart === true ) {
        document.body.style.overflow = 'auto'
      } else {
        document.body.style.overflow = 'hidden'
      }
      this.$store.commit('setShowCart', !this.showCart)
    }
  }
}
