const moment = require('moment')

export default (context, inject) => {
  const api = {
    async request(obj, verify = false, surpress = false, loader = true, passthrough = false) {
      obj.url = `${window.location.origin}/api/v1/${obj.headers.path}`

      // Check hubspot account creation status if applicable
      let hubspotStatus = null
      if (obj.data?.hubspotStatus) {
        hubspotStatus = obj.data.hubspotStatus
        delete obj.data.hubspotStatus // Delete hubspotStatus from data being submitted
      }

      delete obj.headers.path
      if(process.env.REQUIRE_VERIFY && verify) {
        obj.headers.verify = await context.$recaptcha.execute('login')
      }
      if(context.store.state.env) {
        obj.headers.env = context.store.state.env
      } else if(process.env.API_ENV) {
        obj.headers.env = process.env.API_ENV
      } 
      obj.headers.client_type = context.store.state.client
      if(context.store.state.deviceId) {
        obj.headers.device_id = context.store.state.deviceId
      }
      // context.store.commit('setErrors', [])
      if (loader) {
        context.store.commit('setLoading', true)
      }
      return context
        .$axios(obj)
        .then((res) => {
          if (loader) {
            context.store.commit('setLoading', false)
          }
          return res.data
        })
        .catch((err) => {
          context.store.commit('setLoading', false)
          if(passthrough == true) {
            if (err.message) {
              console.error(err.message)
            }
            return err?.response?.data ?? ''
          } else if(surpress === false) {
            if(err?.response?.data && Array.isArray(err.response.data)) {
              if(typeof err.response.data[0] === 'string') {
                context.store.commit('setErrors', err.response.data)
              } else {
                let errors = []
                err.response.data.map(e => {
                  errors.push(e.message)
                })
                context.store.commit('setErrors', errors)
              }
            } else if(err?.response?.data?.message) {
              if (hubspotStatus && err.response.data.message.includes('There was an error with your request. Please try again.')) {
                context.store.commit('setErrors', ['Your account has been successfully created, please log in.'])
              } else if (err.response.data.message.includes('Could not load shopping basket')) {
                console.error(err.response.data.message)
                context.store.commit('setOrder', null)
              } else if (!obj.url.includes('upsells')) { // Hide upsells 'Not Found' error message
                context.store.commit('setErrors', [err.response.data.message])
              }
            } else if (err?.response?.data?.password) {
              context.store.commit('setErrors', [err.response.data.password])
            } else {
              context.store.commit('setErrors', [
                'Connection issue. Please try again.'
              ])
            }
          }
          return null
        })
    },
    // Email subscription
    async subscribe(customer) {
      const subscription = await this.request({
        method: 'post',
        headers: {
          path: 'eclub/subscribe'
        },
        data: customer
      })
      return subscription
    },
    // Users
    async findUser(params) {
      const user = await this.request({
        method: 'get',
        headers: {
          path: 'members/search'
        },
        params
      }, true)
      if(user) {
        return user.status
      } else {
        return 0
      }
    },
    async createUser(customer) {
      const user = await this.request({
        method: 'post',
        headers: {
          path: 'members/create'
        },
        data: customer
      }, true)
      if(user) {
        context.store.commit('setToken', user.tokens)
      }
      return user
    },
    async loginUser(creds) {
      const user = await this.request({
        method: 'post',
        headers: {
          path: 'members/signin'
        },
        data: creds
      }, true)
      if(user) {
        context.store.commit('setToken', user.tokens)
      }
      return user
    },
    async loginSSO(creds) {
      let user = await this.request({
        method: 'post',
        headers: {
          path: 'members/sso',
        },
        data: creds
      })
      if(user) {
        context.store.commit('setToken', user.tokens)
      }
      return user
    },
    async logoutUser() {
      await this.request({
        method: 'delete',
        headers: {
          path: 'members/signout',
          token: context.store.state.token.access.token
        }
      })
      context.store.commit('setUser', null)
      context.store.commit('setToken', null)
      await this.abandonOrder()
      return true
    },
    async deleteAccount() {
      await this.request({
        method: 'delete',
        headers: {
          path: 'members',
          token: context.store.state.token.access.token
        }
      })
      context.store.commit('setUser', null)
      context.store.commit('setToken', null)
      await this.abandonOrder()
      return true
    },
    async getUser(withTokens = false) {
      let request = {
        method: 'get',
        headers: {
          path: 'members/get',
          token: context.store.state.token.access.token
        }
      }
      if(context.store.state.token.id) {
        request.params = {
          memberID: context.store.state.token.id
        }
      }
      if(withTokens) {
        if(!request.params) {
          request.params = {
            withTokens: true
          }
        } else {
          request.params.withTokens = true
        }
      }
      const user = await this.request(request)
      if(user) {
        context.store.commit('setUser', user)
      }
      return user
    },
    async updateUser(d) {
      const user = await this.request({
        method: 'put',
        headers: {
          path: 'members/update',
          token: context.store.state.token.access.token
        },
        data: d
      })
      if(user) {
        context.store.commit('setUser', d)
      }
      return user
    },
    async getFaveLocations() {
      const faves = await this.request({
        method: 'get',
        headers: {
          path: 'members/faves/locations',
          token: context.store.state.token.ordering.access.token
        }
      })
      if(faves) {
        context.store.commit('setFavelocations', faves.favelocations)
      }
      return faves
    },
    async setFaveLocation(id) {
      const faves = await this.request({
        method: 'post',
        headers: {
          path: `members/faves/locations/${id}`,
          token: context.store.state.token.ordering.access.token
        }
      }, false, false, false)
      if(faves) {
        context.store.commit('setFavelocations', faves.favelocations)
      }
      return faves
    },
    async deleteFaveLocation(id) {
      const faves = await this.request({
        method: 'delete',
        headers: {
          path: `members/faves/locations/${id}`,
          token: context.store.state.token.ordering.access.token
        }
      }, false, false, false)
      if(faves) {
        context.store.commit('setFavelocations', faves.favelocations)
      }
      return faves
    },
    // Payments
    async getPayments() {
      const payments = await this.request({
        method: 'get',
        headers: {
          path: `members/payments`,
          token: context.store.state.token.ordering.access.token
        }
      })
      if(payments) {
        const user = JSON.parse(JSON.stringify(context.store.state.user))
        user.payments = payments.payments
        context.store.commit('setUser', user)
      }
      return payments
    },
    async deletePayment(pid) {
      const payments = await this.request({
        method: 'delete',
        headers: {
          path: `members/payments/${pid}`,
          token: context.store.state.token.ordering.access.token
        }
      })
      if(payments != null) {
        const user = JSON.parse(JSON.stringify(context.store.state.user))
        user.payments.map((p, i) => {
          if(p.account.id == pid) {
            user.payments.splice(i, 1)
          }
        })
        context.store.commit('setUser', user)
      }
      return payments
    },
    getPaymentForm() {
      return this.request({
        method: 'post',
        headers: {
          path: `payments/form`
        },
        data: {
          id: context.store.state.token.payments.access.id
        }
      })
    },
    submitPayment(account) {
      return this.request({
        method: 'post',
        headers: {
          path: `payments/submit`,
          token: context.store.state.token.ordering.access.token,
          payment_token: context.store.state.token.payments.access.token
        },
        data: {
          id: context.store.state.token.payments.access.id,
          order_id: context.store.state.order.id,
          store_id: context.store.state.order.menu_id,
          amount: context.store.state.order.totals.total,
          account
        }
      })
    },
    // Addresses
    async getAddresses() {
      const addresses = await this.request({
        method: 'get',
        headers: {
          path: `members/addresses`,
          token: context.store.state.token.ordering.access.token
        }
      })
      if (addresses && Array.isArray(addresses.addresses)) {
        // remove duplicates
        let unique = []
        let added = []
        let check = []
        for (let i = 0; i < addresses.addresses.length; i++) {
          if (addresses.addresses[i].default) { // first add default address
            unique.push(addresses.addresses[i])
            check.push({
              uid: addresses.addresses[i].address + addresses.addresses[i].building + addresses.addresses[i].city,
              address: addresses.addresses[i]
            })
          }
        }
        for (let i = 0; i < addresses.addresses.length; i++) {
          if (!addresses.addresses[i].default) { // skip default, it was already added
            check.push({
              uid: addresses.addresses[i].address + addresses.addresses[i].building + addresses.addresses[i].city,
              address: addresses.addresses[i]
            })
          }
        }
        for (var i = 0; i < check.length; i++) {
          if (!added.includes(check[i].uid)) {
            added.push(check[i].uid)
            if (!check[i].address.default) {
              unique.push(check[i].address)
            }
          } else {
            context.$api.deleteAddress(check[i].address.id)
          }
        }
        // console.log(added)
        // console.log(unique)
        const user = JSON.parse(JSON.stringify(context.store.state.user))
        user.addresses = unique
        context.store.commit('setUser', user)
      }
    },
    async deleteAddress(aid) {
      const addresses = await this.request({
        method: 'delete',
        headers: {
          path: `members/addresses/${aid}`,
          token: context.store.state.token.ordering.access.token
        }
      })

      const user = JSON.parse(JSON.stringify(context.store.state.user))
      user.addresses.map((a, i) => {
        if(a.id == aid) {
          user.addresses.splice(i, 1)
        }
      })
      context.store.commit('setUser', user)
      return addresses
    },
    // Passwords
    async changePassword(newpassword, oldpassword) {
      return await this.request({
        method: 'post',
        headers: {
          path: 'members/password',
          token: context.store.state.token.access.token
        },
        data: {
          new: newpassword,
          current: oldpassword
        }
      })
    },
    async forgotPassword(email) {
      return await this.request({
        method: 'post',
        headers: {
          path: 'members/forgotpassword'
        },
        data: {
          email
        }
      }, true)
    },
    // Locations
    async findLocations(loc, showErrors = true) {
      const locations = await this.request({
        method: 'get',
        headers: {
          path: 'locations/search'
        },
        params: loc
      }, false, false, false)
      context.store.commit('setGeolocation', loc)
      if(locations) {
        if(locations.count > 0) {
          context.store.commit('setLocationList', locations.locations)
        } else {
          context.store.commit('setLocationList', false)

          if (showErrors) {
            context.store.commit('setErrors', ['No locations nearby. Please try a different address.'])
          }
        }
      } else {
        context.store.commit('setLocationList', null)

        if (showErrors) {
          context.store.commit('setErrors', ['No locations nearby. Please try a different address.'])
        }
      }
      return locations
    },
    async findDeliveryLocations(loc) {
      const locations = await this.request({
        method: 'post',
        headers: {
          path: 'locations/delivery'
        },
        data: loc
      })
      context.store.commit('setGeolocation', loc)
      if (locations) {
        let locArray = []
        locArray.push(locations)
        context.store.commit('setLocationList', locArray)
      } else {
        context.store.commit('setErrors', ['No locations nearby. Please try a different address.'])
        context.store.commit('setLocationList', null)
      }
      return locations
    },
    async getLocation(id, byMenuId = false, viewOnly = false) {
      const request = {
        method: 'get',
        headers: {
          path: `locations/${id}`
        }
      }
      if(byMenuId) {
        request.params = {
          byMenuId: true
        }
      }
      const location = await this.request(request)
      if(location && !viewOnly) {
        context.store.commit('setLocation', location)
      }
      return location
    },
    // Menus
    async getMenu() {
      const menuId = context?.store?.state?.location?.menu_id ?? false
      if (!menuId) return false

      const menu = await this.request({
        method: 'get',
        headers: {
          path: `menus/${context.store.state.location.menu_id}`
        }
      }, false, false, false)
      if(menu) {
        context.store.commit('setMenu', menu)
        // reset order if changing locations
        // TODO: transfer order to new location
        if(context.store.state.order && context.store.state.order.menu_id != context.store.state.location.menu_id) {
          context.store.commit('setOrder', null)
        }
        return true
      } else {
        return false
      }
    },
    getProduct(pid) {
      const menuId = context?.store?.state?.location?.menu_id ?? false
      if (!menuId) return false

      return this.request({
        method: 'get',
        headers: {
          path: `menus/${context.store.state.location.menu_id}/products/${pid}`
        }
      })
    },
    // Order History
    async getRecentOrders() {
      if (context.store.state.token) {
        const orders = await this.request({
          methods: 'get',
          headers: {
            path: 'orders/recent',
            token: context.store.state.token.ordering.access.token
          }
        })
        if(orders) {
          context.store.commit('setOrderHistory', orders.orders)
        }
        return orders
      } else {
        return []
      }
    },
    async createReorder(id) {
      const order = await this.request({
        method: 'post',
        headers: {
          path: 'orders/reorder',
          token: context.store.state.token.ordering.access.token
        },
        data: {
          id
        }
      })
      if(order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    // Favorite Orders
    async getFaveOrders() {
      const orders = await this.request({
        method: 'get',
        headers: {
          path: 'orders/faves',
          token: context.store.state.token.ordering.access.token
        }
      })
      return orders
    },
    async createFaveOrder(id, name) {
      const order = await this.request({
        method: 'post',
        headers: {
          path: 'orders/faves',
          token: context.store.state.token.ordering.access.token
        },
        data: {
          id,
          description: name
        }
      })
      return order
    },
    async createFaveReorder(id) {
      const order = await this.request({
        method: 'post',
        headers: {
          path: 'orders/faves/reorder',
          token: context.store.state.token.ordering.access.token
        },
        data: {
          id
        }
      })
      if(order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    // Orders
    async createOrder() {
      await this.abandonOrder()
      let order = null
      const headers = {
        path: 'orders/create'
      }
      if(context.store.state.token) {
        headers.token = context.store.state.token.ordering.access.token
      }
      order = await this.request({
        method: 'post',
        headers,
        data: {
          menu_id: context.store.state.location.menu_id
        }
      })
      if(order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    async retrieveOrder(oid) {
      const order = await this.request({
        method: 'get',
        headers: {
          path: `orders/${oid}`
        }
      }, false, false, false, true)

      if (order) {
        if (order.message) {
          if (
            (order.message.includes('Could not load shopping basket')) ||
            (order.message.includes('is not a valid basket ID'))
          ) {
            return { action: 'redirect', route: '/'}
          }
        }
        
        context.store.commit('setOrder', order)
      }

      return order
    },
    async addToOrder(prods) {
      if(!context.store.state.order) {
        await this.createOrder(context.store.state.location.menu_id)
      }
      const order = await this.request({
        method: 'post',
        headers: {
          path: `orders/${context.store.state.order.id}/products`
        },
        data: {
          products: prods
        }
      })
      if(order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    async updateInOrder(prods) {
      const order = await this.request({
        method: 'put',
        headers: {
          path: `orders/${context.store.state.order.id}/products`
        },
        data: {
          products: prods
        }
      })
      if(order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    async removeFromOrder(pid) {
      const order = await this.request({
        method: 'delete',
        headers: {
          path: `orders/${context.store.state.order.id}/products/${pid}`
        }
      })
      if(order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    async validateOrder(initial = true) {
      const totals = await this.request({
        method: 'post',
        headers: {
          path: `orders/${context.store.state.order.id}/validate`
        }
      }, false, false, false, initial)
      if(totals && totals.message) {
        if (
          (totals.message.includes('Could not load shopping basket')) ||
          (totals.message.includes('is not a valid basket ID'))
        ) {
          console.log('Message:', totals.message)
          context.store.commit('setOrder', null)

          return { action: 'redirect', route: '/'}
        }

        if(
          totals.message.match(/^Your\sorder\scannot\sbe\savailable\sfor\sdelivery\sbefore/)
          || totals.message.match(/^Your\sorder\scannot\sbe\sready\sby/)
        ) {
          // Flip to ASAP
          await this.deleteTime()
          return this.validateOrder(false) // pass false to prevent looping
        } else if(
          totals.message.toLowerCase().match(/closed/)
          || totals.message.toLowerCase().match(/high\sorder\svolume/)
        ) {
          // Flip to next available
          let times = await this.getTimes()
          if(times && times.times.length > 0) {
            await this.setTime(times.times[0].time)
            return this.validateOrder(false) // pass false to prevent looping
          }
        }
        context.store.commit('setErrors', [totals.message])
        return null
      // } else if(totals.errorMessage) {
        // what should we do here?
      } else if(totals) {
        console.log('** totals **', totals)
        const order = JSON.parse(JSON.stringify(context.store.state.order))
        order.taxes = totals.taxes
        
        // check if order totals exist before assigning tax and total
        if (order.totals && order.totals !== null) {
          order.totals.tax = totals.totals?.tax ?? 0
          order.totals.total = totals.totals?.total ?? 0
        } else {
          order.totals = {
            tax: totals.totals?.tax ?? 0,
            total: totals.totals?.total ?? 0,
          }
        }

        context.store.commit('setOrder', order)
        context.store.commit('setSupports', totals.supports)
        // optional chaining and nullish coalescing to handle null and undefined values
        const billing = totals?.billing ?? []
        billing.map(b => {
          if (b.type == 'creditcard' && b.accounts.length > 0) {
            context.store.commit('setAccounts', b.accounts)
            context.store.commit('setSavedPayments', true)
            b.accounts.map(a => {
              if (a.account.default) {
                context.store.commit('setSelectedAccount', a.account.id)
              }
            })
          }
          if (b.type == 'giftcard' && b.accounts.length > 0) {
            context.store.commit('setGiftcards', b.accounts)
          }
        })
      }
      return totals
    },
    async setHandoff(mode) {
      const order = await this.request({
        method: 'put',
        headers: {
          path: `orders/${context.store.state.order.id}/handoff/${mode}`
        }
      })
      if(order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    async setAddress(type, address) {
      const order = await this.request({
        method: 'put',
        headers: {
          path: `orders/${context.store.state.order.id}/address`
        },
        data: {
          type,
          address
        }
      })
      if(order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    async setCustomFields(fields) {
      const order = await this.request({
        method: 'put',
        headers: {
          path: `orders/${context.store.state.order.id}/custom`
        },
        data: {
          fields
        }
      })
      if(order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    getCalendar(from, to) {
      return this.request({
        method: 'get',
        headers: {
          path: `menus/${context.store.state.location.menu_id}/times`
        },
        params: {
          from,
          to
        }
      }, false, false, false)
    },
    async getTimes(date = null) {
      let localOffset = moment().utcOffset() / 60
      let storeOffset = context.store.state.location.utcOffset
      let offset = storeOffset - localOffset
      let selectTime = date || moment().add(offset, 'hours').add(5, 'minutes').format('YYYYMMDD HH:mm')
      let times = await this.request({
        method: 'get',
        headers: {
          path: `orders/${context.store.state.order.id}/time`
        },
        params: {
          date: selectTime
        }
      })
      return times
    },
    async setTime(time) {
      const order = await this.request({
        method: 'put',
        headers: {
          path: `orders/${context.store.state.order.id}/time`
        },
        data: {
          time
        }
      })
      if(order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    async deleteTime() {
      const order = await this.request({
        method: 'delete',
        headers: {
          path: `orders/${context.store.state.order.id}/time`
        }
      })
      if(order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    async addTip(amount) {
      const order = await this.request({
        method: 'put',
        headers: {
          path: `orders/${context.store.state.order.id}/tip`
        },
        data: {
          amount
        }
      }, false, false, false)
      if(order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    async addCoupon(code) {
      const order = await this.request({
        method: 'put',
        headers: {
          path: `orders/${context.store.state.order.id}/coupon`
        },
        data: {
          code
        }
      })
      if(order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    async removeCoupon() {
      const order = await this.request({
        method: 'delete',
        headers: {
          path: `orders/${context.store.state.order.id}/coupon`
        }
      })
      if(order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    giftCardBalance(number, pin) {
      return this.request({
        method: 'post',
        headers: {
          path: `orders/${context.store.state.order.id}/giftcard`
        },
        data: {
          number,
          pin
        }
      }, true)
    },
    getBasketToken() {
      const headers = {
        path: `orders/${context.store.state.order.id}/token`
      }
      if(context.store.state.token) {
        headers.token = context.store.state.token.ordering.access.token
      }
      return this.request({
        method: 'post',
        headers
      })
    },
    submitOrder(billing) {
      let headers = {
        path: `orders/${context.store.state.order.id}/submit`
      }
      let data = {
        usertype: 'guest',
        payments: billing
      }
      if (context.store.state.token && context.store.state.token.ordering.access.token) {
        data.usertype = 'user'
        headers.token = context.store.state.token.ordering.access.token
      } else {
        data.first_name = context.store.state.customer.user.first_name
        data.last_name = context.store.state.customer.user.last_name
        data.email = context.store.state.customer.user.email
        data.phone = context.store.state.customer.user.phone
      }
      return this.request({
        method: 'post',
        headers,
        data
      })
    },
    trackConversion(bid, oid, products) {
      return this.request({
        method: 'post',
        headers: {
          path: `orders/${bid}/track`
        },
        data: {
          id: oid,
          products
        }
      })
    },
    trackPaymentFailure(oid, error) {
      return this.request({
        method: 'post',
        headers: {
          path: `orders/${oid}/track`
        },
        data: {
          error,
          type: 'ccsf'
        }
      })
    },
    getOrderStatus(id) {
      return this.request({
        method: 'get',
        headers: {
          path: `orders/${id}/status`
        }
      })
    },
    cancelOrder(id) {
      return this.request({
        method: 'delete',
        headers: {
          path: `orders/${id}`
        }
      })
    },
    async abandonOrder() {
      if(context.store.state.order) {
        await this.request({
          method: 'delete',
          headers: {
            path: `orders/${context.store.state.order.id}/abandon`
          }
        })
        context.store.commit('setOrder', null)
        return true
      } else {
        return false
      }
    },
    
    //Offers
    getOffers() {
      return this.request({
        method: 'get',
        headers: {
          path: `members/offers/all`,
          token: context.store.state.token.access.token
        },
      })
    },
    getBalance(surpress = false) {
      // console.log(context.store)
      return this.request({
        method: 'get',
        headers: {
          path: `members/balance?filter=false`,
          token: context.store.state.token.access.token
        },
      }, false, true)
    },
    getRedeemedOffers() {
      return this.request({
        method: 'post',
        headers: {
          path: `members/balance?memberID=${context.store.state.token.id}&filter=false`,
          token: context.store.state.token.access.token
        },
      }, false, false, false)
    },
    redeemOfferToAccount(price, code) {
      return this.request({
        method: 'post',
        headers: {
          path: `members/redeem?memberID=${context.store.state.token.id}`,
          token: context.store.state.userToken.access.token
        },
        data: {
          price: price,
          code: code
        }
      }, false, false, false)
    },
    getOffersForOrder() {
      if (context.store.state.token && context.store.state.order) {
        return this.request({
          method: 'get',
          headers: {
            path: `orders/${context.store.state.order.id}/rewards`,
            token: context.store.state.token.access.token
          }
        })
      } else {
        return []
      }
    },
    async applyOfferToOrder(memberid, reference) {
      const order = await this.request({
        method: 'put',
        headers: {
          path: `orders/${context.store.state.order.id}/rewards`,
          token: context.store.state.userToken.access.token
        },
        data: {
          memberid: memberid,
          references: [
            reference
          ]
        }
      })
      if (order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    async removeOfferFromOrder(id) {
      const order = await this.request({
        method: 'delete',
        headers: {
          path: `orders/${context.store.state.order.id}/rewards/${id}`,
          token: context.store.state.userToken.access.token
        }
      })
      if (order) {
        context.store.commit('setOrder', order)
      }
      return order
    },
    getUpsellItems() {
      // if (context.store.state.token) {
        return this.request({
          method: 'get',
          headers: {
            path: `upsells/${context.store.state.order.id}`,
            token: context.store.state.token ? context.store.state.token.access.token : null
          }
        })
      // } else {
      //   return []
      // }
    },
    addUpsellItem(uid, qty) {
      let items = [{
        id: uid,
        quantity: qty,
      }]
      return this.request({
        method: 'post',
        headers: {
          path: `upsells/${context.store.state.order.id}`,
          token: context.store.state.token ? context.store.state.token.access.token : null
        },
        data: {
          items
        }
      })
    },
  }

  inject('api', api)
  context.$api = api
}
