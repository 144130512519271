import Vue from 'vue'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

if(process.env.BUGTRACKER) {
  Bugsnag.start({
    apiKey: process.env.BUGTRACKER,
    plugins: [new BugsnagPluginVue()],
    appVersion: process.env.APP_VERSION,
    releaseStage: process.env.RELEASE_STAGE,
  })

  const bugsnagVue = Bugsnag.getPlugin('vue')
  Vue.use(bugsnagVue)
}
