/* DO NOT EDIT OUTSIDE OF MASTER */

export default (context, inject) => {
  const meta = function(page) {
    const basePath = process.env.BASE_URL
    if(page) {
      const pageMeta = {
        title: page.content.meta_title || page.name,
        meta: [
          {
            hid: 'description',
            name: 'description',
            content: page.content.meta_description
          },
          {
            hid: 'og:title',
            name: 'og:title',
            content: page.content.meta_title || page.name
          },
          {
            hid: 'og:description',
            name: 'og:description',
            content: page.content.meta_description
          }
        ],
        script: [],
        link: [
          {
            rel: 'canonical',
            href: `${basePath}${context.route.path}${context.route.path.match(/\/$/) ? '' : '/'}`
          }
        ]
      }
      if(page.content.meta_scripts) {
        page.content.meta_scripts.map(s => {
          if(s.src.match(/^prod\:/)) {
            if(process.env.API_ENV != 'dev') {
              console.log(s.src)
              pageMeta.script.push({
                src: s.src.replace(/^prod\:/, '')
              })
            }
          } else if(s.src.match(/^dev\:/)) {
            if(process.env.API_ENV == 'dev') {
              console.log(s.src)
              pageMeta.script.push({
                src: s.src.replace(/^dev\:/, '')
              })
            }
          } else {
            console.log(s.src)
            pageMeta.script.push({
              src: s.src
            })
          }
        })
      }
      if(page.content.component == 'product') {
        pageMeta.script.push({
          type: 'application/ld+json',
          json: {
            '@context': 'https://schema.org',
            '@type': 'Product',
            '@id': `${basePath}/`,
            name: page.name,
            description: page.content.description,
            sku: page.content.sku,
            image: page.content.image,
            url: `${basePath}/menu/items/${page.content.sku}`,
            productID: page.content.sku
          }
        })
      } else if(page.content.component == 'location') {
        pageMeta.script.push({
          type: 'application/ld+json',
          json: {
            "@context": "https://schema.org",
            "@type": "LocalBusiness",
            "name": page.name,
            "image": page.content.image,
            "@id": "https://www.perkinsrestaurants.com/",
            "url": `https://www.perkinsrestaurants.com/${page.path}`,
            "telephone": page.content.phone,
            "address": {
                "@type": "PostalAddress",
                "streetAddress": page.content.address,
                "addressLocality": page.content.city,
                "addressRegion": page.content.state,
                "postalCode": page.content.zipcode,
                "addressCountry": page.content.country
            },
            "openingHoursSpecification": [
              {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": "Sunday",
                "opens": page.content.hours[0].sun_open,
                "closes": page.content.hours[0].sun_close
              },
              {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": "Monday",
                "opens": page.content.hours[0].mon_open,
                "closes": page.content.hours[0].mon_close
              },
              {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": "Tuesday",
                "opens": page.content.hours[0].tue_open,
                "closes": page.content.hours[0].tue_close
              },
              {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": "Wednesday",
                "opens": page.content.hours[0].wed_open,
                "closes": page.content.hours[0].wed_close
              },
              {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": "Thursday",
                "opens": page.content.hours[0].thu_open,
                "closes": page.content.hours[0].thu_close
              },
              {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": "Friday",
                "opens": page.content.hours[0].fri_open,
                "closes": page.content.hours[0].fri_close
              },
              {
                "@type": "OpeningHoursSpecification",
                "dayOfWeek": "Saturday",
                "opens": page.content.hours[0].sat_open,
                "closes": page.content.hours[0].sat_close
              },
            ]
          }
        })
      }
      return pageMeta
    } else {
      return {
        title: 'Not Found'
      }
    }
  }

  inject('metadata', meta)
  context.$metadata = meta
}
