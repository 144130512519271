//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState([ 'location', 'menu', 'loading', 'errors', 'showCart', 'timePicker' ])
  },
  async mounted() {
    try {
      await this.$recaptcha.init()
    } catch(err) {
      console.error(err)
    }
    if(this.$route.query.menu) {
      await this.$api.getLocation(this.$route.query.menu, true)
      await this.$api.createOrder()
    } else if(this.$route.query.store) {
      await this.$api.getLocation(this.$route.query.store)
      await this.$api.createOrder()
    }
    if(this.location) {
      await this.$api.getMenu()
    }
    window.addEventListener('resize', this.resize)
  },
  destroyed() {
    window.removeEventListener('resize', this.resize)
  },
  methods: {
    resize() {
      if (this.$mq == 'lg' && this.$mq == 'xl') {
        this.$store.commit('setShowNav', false)
        document.body.style.overflow = 'auto'
      }
    }
  },
  watch: {
    '$route.path'(e) {
      if (this.$mq == 'sm' || this.$mq == 'md') {
        this.$store.commit('setShowNav', false)
        document.body.style.overflow = 'auto'
      }
      this.$store.commit('setErrors', [])
      window.scroll({ top: 0, left: 0, behavior: 'smooth' }) // scroll to top on route change
    }
  }
}
