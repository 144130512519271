import Vue from 'vue'
import { wrapFunctional } from './utils'

const components = {
  AccountForgot: () => import('../../components/account/AccountForgot.vue' /* webpackChunkName: "components/account-forgot" */).then(c => wrapFunctional(c.default || c)),
  AccountHero: () => import('../../components/account/AccountHero.vue' /* webpackChunkName: "components/account-hero" */).then(c => wrapFunctional(c.default || c)),
  AccountHistory: () => import('../../components/account/AccountHistory.vue' /* webpackChunkName: "components/account-history" */).then(c => wrapFunctional(c.default || c)),
  AccountHome: () => import('../../components/account/AccountHome.vue' /* webpackChunkName: "components/account-home" */).then(c => wrapFunctional(c.default || c)),
  AccountInfo: () => import('../../components/account/AccountInfo.vue' /* webpackChunkName: "components/account-info" */).then(c => wrapFunctional(c.default || c)),
  AccountPreferences: () => import('../../components/account/AccountPreferences.vue' /* webpackChunkName: "components/account-preferences" */).then(c => wrapFunctional(c.default || c)),
  AccountSignin: () => import('../../components/account/AccountSignin.vue' /* webpackChunkName: "components/account-signin" */).then(c => wrapFunctional(c.default || c)),
  AccountSignup: () => import('../../components/account/AccountSignup.vue' /* webpackChunkName: "components/account-signup" */).then(c => wrapFunctional(c.default || c)),
  AccountTransfer: () => import('../../components/account/AccountTransfer.vue' /* webpackChunkName: "components/account-transfer" */).then(c => wrapFunctional(c.default || c)),
  AccountUpdate: () => import('../../components/account/AccountUpdate.vue' /* webpackChunkName: "components/account-update" */).then(c => wrapFunctional(c.default || c)),
  AppSignin: () => import('../../components/account/AppSignin.vue' /* webpackChunkName: "components/app-signin" */).then(c => wrapFunctional(c.default || c)),
  FAQ: () => import('../../components/account/FAQ.vue' /* webpackChunkName: "components/f-a-q" */).then(c => wrapFunctional(c.default || c)),
  OfferHistory: () => import('../../components/account/OfferHistory.vue' /* webpackChunkName: "components/offer-history" */).then(c => wrapFunctional(c.default || c)),
  OrderHistoryOld: () => import('../../components/account/OrderHistoryOld.vue' /* webpackChunkName: "components/order-history-old" */).then(c => wrapFunctional(c.default || c)),
  AppLink: () => import('../../components/common/AppLink.vue' /* webpackChunkName: "components/app-link" */).then(c => wrapFunctional(c.default || c)),
  BackButton: () => import('../../components/common/BackButton.vue' /* webpackChunkName: "components/back-button" */).then(c => wrapFunctional(c.default || c)),
  Card: () => import('../../components/common/Card.vue' /* webpackChunkName: "components/card" */).then(c => wrapFunctional(c.default || c)),
  CardSection: () => import('../../components/common/CardSection.vue' /* webpackChunkName: "components/card-section" */).then(c => wrapFunctional(c.default || c)),
  CardSliderSection: () => import('../../components/common/CardSliderSection.vue' /* webpackChunkName: "components/card-slider-section" */).then(c => wrapFunctional(c.default || c)),
  Contact: () => import('../../components/common/Contact.vue' /* webpackChunkName: "components/contact" */).then(c => wrapFunctional(c.default || c)),
  DefaultButton: () => import('../../components/common/DefaultButton.vue' /* webpackChunkName: "components/default-button" */).then(c => wrapFunctional(c.default || c)),
  Favorites: () => import('../../components/common/Favorites.vue' /* webpackChunkName: "components/favorites" */).then(c => wrapFunctional(c.default || c)),
  Loader: () => import('../../components/common/Loader.vue' /* webpackChunkName: "components/loader" */).then(c => wrapFunctional(c.default || c)),
  Lottie: () => import('../../components/common/Lottie.vue' /* webpackChunkName: "components/lottie" */).then(c => wrapFunctional(c.default || c)),
  MenuSection: () => import('../../components/common/MenuSection.vue' /* webpackChunkName: "components/menu-section" */).then(c => wrapFunctional(c.default || c)),
  Paragraph: () => import('../../components/common/Paragraph.vue' /* webpackChunkName: "components/paragraph" */).then(c => wrapFunctional(c.default || c)),
  Promotion: () => import('../../components/common/Promotion.vue' /* webpackChunkName: "components/promotion" */).then(c => wrapFunctional(c.default || c)),
  RadarMap: () => import('../../components/common/RadarMap.vue' /* webpackChunkName: "components/radar-map" */).then(c => wrapFunctional(c.default || c)),
  StickyNav: () => import('../../components/common/StickyNav.vue' /* webpackChunkName: "components/sticky-nav" */).then(c => wrapFunctional(c.default || c)),
  Teaser: () => import('../../components/common/Teaser.vue' /* webpackChunkName: "components/teaser" */).then(c => wrapFunctional(c.default || c)),
  TextPage: () => import('../../components/common/TextPage.vue' /* webpackChunkName: "components/text-page" */).then(c => wrapFunctional(c.default || c)),
  DynamicForm: () => import('../../components/forms/DynamicForm.vue' /* webpackChunkName: "components/dynamic-form" */).then(c => wrapFunctional(c.default || c)),
  IconAccount: () => import('../../components/icons/Account.vue' /* webpackChunkName: "components/icon-account" */).then(c => wrapFunctional(c.default || c)),
  IconAdd: () => import('../../components/icons/Add.vue' /* webpackChunkName: "components/icon-add" */).then(c => wrapFunctional(c.default || c)),
  IconCheckbox: () => import('../../components/icons/Checkbox.vue' /* webpackChunkName: "components/icon-checkbox" */).then(c => wrapFunctional(c.default || c)),
  IconCheckboxChecked: () => import('../../components/icons/CheckboxChecked.vue' /* webpackChunkName: "components/icon-checkbox-checked" */).then(c => wrapFunctional(c.default || c)),
  IconCircleArrow: () => import('../../components/icons/CircleArrow.vue' /* webpackChunkName: "components/icon-circle-arrow" */).then(c => wrapFunctional(c.default || c)),
  IconDelivery: () => import('../../components/icons/Delivery.vue' /* webpackChunkName: "components/icon-delivery" */).then(c => wrapFunctional(c.default || c)),
  IconFacebook: () => import('../../components/icons/Facebook.vue' /* webpackChunkName: "components/icon-facebook" */).then(c => wrapFunctional(c.default || c)),
  IconFaq: () => import('../../components/icons/Faq.vue' /* webpackChunkName: "components/icon-faq" */).then(c => wrapFunctional(c.default || c)),
  IconHistory: () => import('../../components/icons/History.vue' /* webpackChunkName: "components/icon-history" */).then(c => wrapFunctional(c.default || c)),
  IconHouse: () => import('../../components/icons/House.vue' /* webpackChunkName: "components/icon-house" */).then(c => wrapFunctional(c.default || c)),
  IconPhone: () => import('../../components/icons/IconPhone.vue' /* webpackChunkName: "components/icon-phone" */).then(c => wrapFunctional(c.default || c)),
  IconInstagram: () => import('../../components/icons/Instagram.vue' /* webpackChunkName: "components/icon-instagram" */).then(c => wrapFunctional(c.default || c)),
  IconLinkedIn: () => import('../../components/icons/LinkedIn.vue' /* webpackChunkName: "components/icon-linked-in" */).then(c => wrapFunctional(c.default || c)),
  IconLocate: () => import('../../components/icons/Locate.vue' /* webpackChunkName: "components/icon-locate" */).then(c => wrapFunctional(c.default || c)),
  IconMinus: () => import('../../components/icons/Minus.vue' /* webpackChunkName: "components/icon-minus" */).then(c => wrapFunctional(c.default || c)),
  IconOffer: () => import('../../components/icons/Offer.vue' /* webpackChunkName: "components/icon-offer" */).then(c => wrapFunctional(c.default || c)),
  IconPlus: () => import('../../components/icons/Plus.vue' /* webpackChunkName: "components/icon-plus" */).then(c => wrapFunctional(c.default || c)),
  IconPreferences: () => import('../../components/icons/Preferences.vue' /* webpackChunkName: "components/icon-preferences" */).then(c => wrapFunctional(c.default || c)),
  IconRadio: () => import('../../components/icons/Radio.vue' /* webpackChunkName: "components/icon-radio" */).then(c => wrapFunctional(c.default || c)),
  IconRadioChecked: () => import('../../components/icons/RadioChecked.vue' /* webpackChunkName: "components/icon-radio-checked" */).then(c => wrapFunctional(c.default || c)),
  IconTikTok: () => import('../../components/icons/TikTok.vue' /* webpackChunkName: "components/icon-tik-tok" */).then(c => wrapFunctional(c.default || c)),
  IconTrash: () => import('../../components/icons/Trash.vue' /* webpackChunkName: "components/icon-trash" */).then(c => wrapFunctional(c.default || c)),
  IconTrashDark: () => import('../../components/icons/TrashDark.vue' /* webpackChunkName: "components/icon-trash-dark" */).then(c => wrapFunctional(c.default || c)),
  IconTwitter: () => import('../../components/icons/Twitter.vue' /* webpackChunkName: "components/icon-twitter" */).then(c => wrapFunctional(c.default || c)),
  IconUser: () => import('../../components/icons/User.vue' /* webpackChunkName: "components/icon-user" */).then(c => wrapFunctional(c.default || c)),
  CartIcon: () => import('../../components/layout/CartIcon.vue' /* webpackChunkName: "components/cart-icon" */).then(c => wrapFunctional(c.default || c)),
  Column: () => import('../../components/layout/Column.vue' /* webpackChunkName: "components/column" */).then(c => wrapFunctional(c.default || c)),
  ErrorOverlay: () => import('../../components/layout/ErrorOverlay.vue' /* webpackChunkName: "components/error-overlay" */).then(c => wrapFunctional(c.default || c)),
  Footer: () => import('../../components/layout/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c)),
  Hamburger: () => import('../../components/layout/Hamburger.vue' /* webpackChunkName: "components/hamburger" */).then(c => wrapFunctional(c.default || c)),
  Header: () => import('../../components/layout/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c)),
  Logo: () => import('../../components/layout/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c)),
  MainNav: () => import('../../components/layout/MainNav.vue' /* webpackChunkName: "components/main-nav" */).then(c => wrapFunctional(c.default || c)),
  MenuIcon: () => import('../../components/layout/MenuIcon.vue' /* webpackChunkName: "components/menu-icon" */).then(c => wrapFunctional(c.default || c)),
  MenuPromo: () => import('../../components/layout/MenuPromo.vue' /* webpackChunkName: "components/menu-promo" */).then(c => wrapFunctional(c.default || c)),
  MinimalHeader: () => import('../../components/layout/MinimalHeader.vue' /* webpackChunkName: "components/minimal-header" */).then(c => wrapFunctional(c.default || c)),
  MinimalLogo: () => import('../../components/layout/MinimalLogo.vue' /* webpackChunkName: "components/minimal-logo" */).then(c => wrapFunctional(c.default || c)),
  MobileNav: () => import('../../components/layout/MobileNav.vue' /* webpackChunkName: "components/mobile-nav" */).then(c => wrapFunctional(c.default || c)),
  Page: () => import('../../components/layout/Page.vue' /* webpackChunkName: "components/page" */).then(c => wrapFunctional(c.default || c)),
  TopBanner: () => import('../../components/layout/TopBanner.vue' /* webpackChunkName: "components/top-banner" */).then(c => wrapFunctional(c.default || c)),
  UserIcon: () => import('../../components/layout/UserIcon.vue' /* webpackChunkName: "components/user-icon" */).then(c => wrapFunctional(c.default || c)),
  LocationDelivery: () => import('../../components/locations/LocationDelivery.vue' /* webpackChunkName: "components/location-delivery" */).then(c => wrapFunctional(c.default || c)),
  LocationFilter: () => import('../../components/locations/LocationFilter.vue' /* webpackChunkName: "components/location-filter" */).then(c => wrapFunctional(c.default || c)),
  LocationHours: () => import('../../components/locations/LocationHours.vue' /* webpackChunkName: "components/location-hours" */).then(c => wrapFunctional(c.default || c)),
  LocationItemOld: () => import('../../components/locations/LocationItemOld.vue' /* webpackChunkName: "components/location-item-old" */).then(c => wrapFunctional(c.default || c)),
  LocationPageOld: () => import('../../components/locations/LocationPageOld.vue' /* webpackChunkName: "components/location-page-old" */).then(c => wrapFunctional(c.default || c)),
  LocationSearchOld: () => import('../../components/locations/LocationSearchOld.vue' /* webpackChunkName: "components/location-search-old" */).then(c => wrapFunctional(c.default || c)),
  LocationServices: () => import('../../components/locations/LocationServices.vue' /* webpackChunkName: "components/location-services" */).then(c => wrapFunctional(c.default || c)),
  Locations: () => import('../../components/locations/Locations.vue' /* webpackChunkName: "components/locations" */).then(c => wrapFunctional(c.default || c)),
  LocationsListOld: () => import('../../components/locations/LocationsListOld.vue' /* webpackChunkName: "components/locations-list-old" */).then(c => wrapFunctional(c.default || c)),
  LocationsMap: () => import('../../components/locations/LocationsMap.vue' /* webpackChunkName: "components/locations-map" */).then(c => wrapFunctional(c.default || c)),
  LocationsResultsOld: () => import('../../components/locations/LocationsResultsOld.vue' /* webpackChunkName: "components/locations-results-old" */).then(c => wrapFunctional(c.default || c)),
  LocationsSearch: () => import('../../components/locations/LocationsSearch.vue' /* webpackChunkName: "components/locations-search" */).then(c => wrapFunctional(c.default || c)),
  LocationsSearchColumnOld: () => import('../../components/locations/LocationsSearchColumnOld.vue' /* webpackChunkName: "components/locations-search-column-old" */).then(c => wrapFunctional(c.default || c)),
  OrderTime: () => import('../../components/locations/OrderTime.vue' /* webpackChunkName: "components/order-time" */).then(c => wrapFunctional(c.default || c)),
  Cart: () => import('../../components/order/Cart.vue' /* webpackChunkName: "components/cart" */).then(c => wrapFunctional(c.default || c)),
  Checkout: () => import('../../components/order/Checkout.vue' /* webpackChunkName: "components/checkout" */).then(c => wrapFunctional(c.default || c)),
  CheckoutOffers: () => import('../../components/order/CheckoutOffers.vue' /* webpackChunkName: "components/checkout-offers" */).then(c => wrapFunctional(c.default || c)),
  CheckoutOrderTime: () => import('../../components/order/CheckoutOrderTime.vue' /* webpackChunkName: "components/checkout-order-time" */).then(c => wrapFunctional(c.default || c)),
  Coupons: () => import('../../components/order/Coupons.vue' /* webpackChunkName: "components/coupons" */).then(c => wrapFunctional(c.default || c)),
  CustomerDetails: () => import('../../components/order/CustomerDetails.vue' /* webpackChunkName: "components/customer-details" */).then(c => wrapFunctional(c.default || c)),
  DateTimePicker: () => import('../../components/order/DateTimePicker.vue' /* webpackChunkName: "components/date-time-picker" */).then(c => wrapFunctional(c.default || c)),
  DeliveryDetails: () => import('../../components/order/DeliveryDetails.vue' /* webpackChunkName: "components/delivery-details" */).then(c => wrapFunctional(c.default || c)),
  FiservPayment: () => import('../../components/order/FiservPayment.vue' /* webpackChunkName: "components/fiserv-payment" */).then(c => wrapFunctional(c.default || c)),
  GiftCardForm: () => import('../../components/order/GiftCardForm.vue' /* webpackChunkName: "components/gift-card-form" */).then(c => wrapFunctional(c.default || c)),
  GiftCards: () => import('../../components/order/GiftCards.vue' /* webpackChunkName: "components/gift-cards" */).then(c => wrapFunctional(c.default || c)),
  MenuNav: () => import('../../components/order/MenuNav.vue' /* webpackChunkName: "components/menu-nav" */).then(c => wrapFunctional(c.default || c)),
  MenuPage: () => import('../../components/order/MenuPage.vue' /* webpackChunkName: "components/menu-page" */).then(c => wrapFunctional(c.default || c)),
  OloPayment: () => import('../../components/order/OloPayment.vue' /* webpackChunkName: "components/olo-payment" */).then(c => wrapFunctional(c.default || c)),
  OloPaymentWeb: () => import('../../components/order/OloPaymentWeb.vue' /* webpackChunkName: "components/olo-payment-web" */).then(c => wrapFunctional(c.default || c)),
  OrderAgain: () => import('../../components/order/OrderAgain.vue' /* webpackChunkName: "components/order-again" */).then(c => wrapFunctional(c.default || c)),
  OrderCategories: () => import('../../components/order/OrderCategories.vue' /* webpackChunkName: "components/order-categories" */).then(c => wrapFunctional(c.default || c)),
  OrderConfirmation: () => import('../../components/order/OrderConfirmation.vue' /* webpackChunkName: "components/order-confirmation" */).then(c => wrapFunctional(c.default || c)),
  OrderHistory: () => import('../../components/order/OrderHistory.vue' /* webpackChunkName: "components/order-history" */).then(c => wrapFunctional(c.default || c)),
  OrderItems: () => import('../../components/order/OrderItems.vue' /* webpackChunkName: "components/order-items" */).then(c => wrapFunctional(c.default || c)),
  OrderOffers: () => import('../../components/order/OrderOffers.vue' /* webpackChunkName: "components/order-offers" */).then(c => wrapFunctional(c.default || c)),
  OrderSummary: () => import('../../components/order/OrderSummary.vue' /* webpackChunkName: "components/order-summary" */).then(c => wrapFunctional(c.default || c)),
  OrderTotals: () => import('../../components/order/OrderTotals.vue' /* webpackChunkName: "components/order-totals" */).then(c => wrapFunctional(c.default || c)),
  PaymentConfirm: () => import('../../components/order/PaymentConfirm.vue' /* webpackChunkName: "components/payment-confirm" */).then(c => wrapFunctional(c.default || c)),
  PaymentFrame: () => import('../../components/order/PaymentFrame.vue' /* webpackChunkName: "components/payment-frame" */).then(c => wrapFunctional(c.default || c)),
  PickupMode: () => import('../../components/order/PickupMode.vue' /* webpackChunkName: "components/pickup-mode" */).then(c => wrapFunctional(c.default || c)),
  SavedPayments: () => import('../../components/order/SavedPayments.vue' /* webpackChunkName: "components/saved-payments" */).then(c => wrapFunctional(c.default || c)),
  SubmitOrder: () => import('../../components/order/SubmitOrder.vue' /* webpackChunkName: "components/submit-order" */).then(c => wrapFunctional(c.default || c)),
  TimeDetails: () => import('../../components/order/TimeDetails.vue' /* webpackChunkName: "components/time-details" */).then(c => wrapFunctional(c.default || c)),
  TimePicker: () => import('../../components/order/TimePicker.vue' /* webpackChunkName: "components/time-picker" */).then(c => wrapFunctional(c.default || c)),
  Tips: () => import('../../components/order/Tips.vue' /* webpackChunkName: "components/tips" */).then(c => wrapFunctional(c.default || c)),
  Upsells: () => import('../../components/order/Upsells.vue' /* webpackChunkName: "components/upsells" */).then(c => wrapFunctional(c.default || c)),
  Utensils: () => import('../../components/order/Utensils.vue' /* webpackChunkName: "components/utensils" */).then(c => wrapFunctional(c.default || c)),
  VehicleDetails: () => import('../../components/order/VehicleDetails.vue' /* webpackChunkName: "components/vehicle-details" */).then(c => wrapFunctional(c.default || c)),
  MenuCategoriesOld: () => import('../../components/products/MenuCategoriesOld.vue' /* webpackChunkName: "components/menu-categories-old" */).then(c => wrapFunctional(c.default || c)),
  MenuCategoryOld: () => import('../../components/products/MenuCategoryOld.vue' /* webpackChunkName: "components/menu-category-old" */).then(c => wrapFunctional(c.default || c)),
  MenuFullOld: () => import('../../components/products/MenuFullOld.vue' /* webpackChunkName: "components/menu-full-old" */).then(c => wrapFunctional(c.default || c)),
  ProductConfiguration: () => import('../../components/products/ProductConfiguration.vue' /* webpackChunkName: "components/product-configuration" */).then(c => wrapFunctional(c.default || c)),
  ProductDetails: () => import('../../components/products/ProductDetails.vue' /* webpackChunkName: "components/product-details" */).then(c => wrapFunctional(c.default || c)),
  ProductImage: () => import('../../components/products/ProductImage.vue' /* webpackChunkName: "components/product-image" */).then(c => wrapFunctional(c.default || c)),
  ProductModifierList: () => import('../../components/products/ProductModifierList.vue' /* webpackChunkName: "components/product-modifier-list" */).then(c => wrapFunctional(c.default || c)),
  ProductModifiers: () => import('../../components/products/ProductModifiers.vue' /* webpackChunkName: "components/product-modifiers" */).then(c => wrapFunctional(c.default || c)),
  ProductPage: () => import('../../components/products/ProductPage.vue' /* webpackChunkName: "components/product-page" */).then(c => wrapFunctional(c.default || c)),
  ProductRequests: () => import('../../components/products/ProductRequests.vue' /* webpackChunkName: "components/product-requests" */).then(c => wrapFunctional(c.default || c))
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
