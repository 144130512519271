import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=06a9e75f&scoped=true&"
import script from "./Header.vue?vue&type=script&lang=js&"
export * from "./Header.vue?vue&type=script&lang=js&"
import style0 from "./Header.vue?vue&type=style&index=0&id=06a9e75f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06a9e75f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MenuIcon: require('/opt/build/repo/components/layout/MenuIcon.vue').default,Logo: require('/opt/build/repo/components/layout/Logo.vue').default,MobileNav: require('/opt/build/repo/components/layout/MobileNav.vue').default,MainNav: require('/opt/build/repo/components/layout/MainNav.vue').default,IconUser: require('/opt/build/repo/components/icons/User.vue').default,IconDelivery: require('/opt/build/repo/components/icons/Delivery.vue').default,IconHouse: require('/opt/build/repo/components/icons/House.vue').default,CartIcon: require('/opt/build/repo/components/layout/CartIcon.vue').default,Header: require('/opt/build/repo/components/layout/Header.vue').default})
