//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState([ 'showNav' ]),
    mq() {
      return this.$mq
    }
  },
  methods: {
    toggleNav() {
      document.body.style.overflow = !this.showNav ? 'hidden' : 'auto'
      this.$store.commit('setShowNav', !this.showNav)

      if (this.showNav) {
        this.$store.commit('setScrollTop', document.documentElement.scrollTop)
      }
    }
  }
}
