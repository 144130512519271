//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import moment from 'moment'

export default {
  data() {
    return {
      border: false
    }
  },
  props: {
    layout: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapState([ 'showNav', 'user', 'location', 'order', 'showCart', 'lastActive', 'fixedHeader' ]),
    mq() {
      return this.$mq
    },
    isOrder() {
      if (this.$route.fullPath === '/order' || this.$route.fullPath === '/order/' || this.$route.fullPath === '/menu' || this.$route.fullPath === '/menu/') {
        return true
      } else {
        return false
      }
    },
    headerClass() {
      return this.isOrder ? 'transparent-header' : ''
    }
    // isHome() {
    //   if (this.$route.fullPath === '/') {
    //     return true
    //   } else {
    //     return false
    //   }
    // }
  },
  // beforeMount() {
  //   this.clearOrder()
  // },
  mounted() {
    window.addEventListener('scroll',this.scroll)
    if (!this.$nuxt.context.from) {
      this.clearOrder()
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.scroll)
  },
  methods: {
    scroll() {
      let header = document.getElementById('header')
      if (window.scrollY > 20) {
        this.border = true
        header.classList.remove('transparent-header')
      } else {
        this.border = false
        if (this.$route.fullPath === '/order/' || this.$route.fullPath === '/menu/') {
          header.classList.add('transparent-header')
        }
      }
    },
    async logOut() {
      this.$store.dispatch('accountSignout')
      if (this.order) {
        await this.$api.abandonOrder()
      }
    },
    async clearOrder() {
      // Basket caching
      if (this.order) {
        // console.log(this.order)
        this.$store.commit('setCachingBasket', false)
        let today = new Date()
        let clearOrderAt = new Date(this.order.reset)

        // console.log('Now: ', today)
        // console.log('Clear Order: ', clearOrderAt)

        if (moment(clearOrderAt).isBefore(moment(today))) {
          this.$store.commit('setCachingBasket', true)
          console.log('basket cleared')
          if (this.$route.fullPath === '/order/checkout') {
            this.$router.push('/order')
            this.clearOrderItems()
            setTimeout(() => {
              this.$store.commit('setErrors', ['Your basket timed out, please recreate your order'])
            }, "1000")
          } else {
            this.clearOrderItems()
          }
        } else {
          this.$store.commit('setCachingBasket', false)
        }
      }
    },
    async clearOrderItems() {
      console.log('before: ', this.order)
      if (this.order.products && this.order.products.length) {
        this.order.products.map(async (p) => {
          let order = await this.$api.removeFromOrder(p.id)
          this.$store.commit('setOrder', order)
        })
      }
      if (this.order.coupon) {
        await this.$api.removeCoupon(this.order.coupon.couponcode) 
      }
      console.log('after: ', this.order)
    },
  },
  watch: {
    $route(e) {
      // console.log(e.fullPath)
      this.clearOrder()
      // }
    }
  }
}
