/* DO NOT EDIT OUTSIDE OF MASTER */

export const state = () => ({
  type: null,
  user: {
    first_name: null,
    last_name: null,
    email: null,
    phone: null
  },
  delivery: {
    address: null,
    building: null,
    city: null,
    zip: null,
    instructions: null,
    default: false
  },
  vehicle: {}
})

export const mutations = {
  setCustomerType(state, str) {
    state.type = str
  },

  setCustomer(state, obj) {
    state.user = obj
  },

  setDeliveryAddress(state, obj) {
    state.delivery = obj
  },

  setVehicle(state, obj) {
    state.vehicle = obj
  }
}