//
//
//
//
//
//
//

import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState([ 'icons' ]),
    mq() {
      return this.$mq
    }
  }
}
