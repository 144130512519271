//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState([ 'errors' ]),
    errorText() {
      if (this.errors.includes('Your account has been successfully created, please log in.')) {
        return 'Continue to Log In'
      } else {
        return 'Okay'
      }
    }
  },
  methods: {
    clearErrors() {
      if (this.errors.includes('Your account has been successfully created, please log in.')) {
        this.$router.push('/account/signin')
      }
      this.$store.commit('setErrors', [])
    }
  }
}
