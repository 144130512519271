//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState([ 'options', 'navMenus' ]),
    year() {
      return moment().year()
    },
    one() {
      return this.navMenus['footer-menu-column-one'] ? this.navMenus['footer-menu-column-one'] : []
    },
    two() {
      return this.navMenus['footer-menu-column-two'] ? this.navMenus['footer-menu-column-two'] : []
    },
    three() {
      return this.navMenus['footer-menu-column-three'] ? this.navMenus['footer-menu-column-three'] : []
    },
    mobile() {
      return this.one.concat(this.two).concat(this.three)
    },
    copyright() {
      return '©'+this.year+' '+this.options['copyright']
    },
    mobile() {
      if (this.$mq === 'sm' || this.$mq === 'md') {
        return true
      } else {
        return false
      }
    }
  },
  data() {
    return {
      mq: this.$mq,
      isApp: false,
    }
  },
  beforeMount() {
    if (window && window.navigator && window.navigator.userAgent && window.navigator.userAgent.toLowerCase() === 'reactnative') {
      this.isApp = true
    } else {
      this.isApp = false
    }
  }
}
