//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

export default {
  data() {
    return {
      handoffOpen: false
    }
  },
  computed: {
    ...mapState([ 'navMenus', 'showNav', 'user', 'location', 'order', 'showCart', 'scrollTop' ]),
    links() {
      return this.navMenus['mobile-menu'] ? this.navMenus['mobile-menu'] : []
    },
    mobile() {
      if (this.$mq == 'sm' || this.$mq == 'md') {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    toggleNav() {
      this.$store.commit('setShowNav', !this.showNav)
      
      if (this.showNav) {
        document.body.style.overflow = 'hidden'
        return
      }
      
      if (!this.showCart) {
        document.body.style.overflow = 'auto'
      }

      if (!this.showNav) {
        setTimeout(() => window.scroll({ top: this.scrollTop, behavior: 'smooth' }), 0)
      }
    },
    toggleHandoff() {
      this.handoffOpen = !this.handoffOpen
      const nav = document.querySelector('.mobile-nav')
      setTimeout(() => {
        nav.scrollTop = nav.scrollHeight
      },250)
    },
    async setHandoff(type) {
      if (type == 'delivery' && !this.location.services.dispatch && !this.location.services.delivery) {
        this.$store.commit('setErrors', ['Delivery is not supported for this location.'])
      } else if (!this.location.services.pickup) {
        this.$store.commit('setErrors', ['Pickup is not supported for this location.'])
      } else  {        
        if(type == 'delivery' || type == 'dispatch') {
          this.$store.commit('setSwitchHandoff', true)
          this.$router.push('/')
        } else {
          await this.$api.setHandoff(type)
        }
      }
    },
    logOut() {
      document.body.style.overflow = !this.showNav ? 'hidden' : 'auto'
      this.$store.dispatch('accountSignout')
      this.$store.commit('setShowNav', !this.showNav)
    },
  }
}
