//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'

export default {
  data() {
    return {
      hour: null,
      minute: null,
      meridiem: null,
      open: false
    }
  },
  props: {
    min: {
      type: String
    },
    max: {
      type: String
    },
    time: {
      type: String
    },
    date: {
      type: String
    }
  },
  mounted() {
    const time_part_array = this.time.split(":")
    let ampm = 'AM'
    if (time_part_array[0] >= 12) {
        ampm = 'PM'
    }
    if (time_part_array[0] > 12) {
        time_part_array[0] = time_part_array[0] - 12
    }
    this.hour = time_part_array[0]
    this.minute = Math.ceil(time_part_array[1]/5)*5 // round to nearest 5
    if (this.minute == 60) {
      this.minute = '00'
      this.hour++
      if (this.hour == 13) { // reset to 1 if hour is too high
        this.hour = 1
      }
    }
    if (Array.from(this.hour)[0] == '0') {
      this.hour = Array.from(this.hour)[1]
    }
    if (this.minute.toString().length == 1) { // add zero if one char
      this.minute = '0'+this.minute
    }
    this.meridiem = ampm
  },
  methods: {
    formatTime(time) {
      const time_part_array = time.split(":")
      let ampm = 'AM'
      if (time_part_array[0] >= 12) {
          ampm = 'PM'
      }
      if (time_part_array[0] > 12) {
          time_part_array[0] = time_part_array[0] - 12
      }
      const formatted_time = time_part_array[0] + ':' + time_part_array[1] + ' ' + ampm
      return formatted_time
    },
    clickOutside(e) {
      if (e.target.className !== 'time' && e.target.parentElement.className !== 'time') { // hide if clicking outside, except for time togggle
        this.open = false
      }
    },
    setSelectedTime() {
      setTimeout(function() {
        let time = document.querySelector('.time').innerText.trim()
        time = moment(time,'LT').format('HH:mm')
        this.$emit('setSelectedTime',time)
      }.bind(this), 100)
    }
  },
  watch: {
    hour: function () {
      this.setSelectedTime()
    },
    minute: function () {
      this.setSelectedTime()
    },
    meridiem: function () {
      this.setSelectedTime()
    },
  }
}
