//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
import { mapState } from 'vuex'

export default {
  props: {
    layout: {
      type: String,
      default: null
    }
  },
  computed: {
    ...mapState([ 'location', 'order', 'showCart', 'user' ]),
    orderDay() {
      if (!this.order) return

      let time = this.order.time.earliest
      if (this.order.time.mode == 'advance') {
        time = this.order.time.wanted
      }
      time = moment(time,'YYYYMMDD H:mm')
      let day = time.format('dddd')
      if (time.isSame(new Date(), 'day')) {
        day = 'Today'
      }
      return day
    },
    orderTime() {
      if (!this.order) return

      let time = this.order.time.earliest
      if (this.order.time.mode == 'advance') {
        time = this.order.time.wanted
      }
      time = moment(time,'YYYYMMDD H:mm')
      time = time.format('h:mm A')
      return time
    },
    count() {
      if (!this.order || !this.order.products || this.order.products.length === 0) {
        return 0;
      }

      return this.order.products
        .filter(product => !product.name.includes('Utensils'))
        .reduce((total, product) => total + product.quantity, 0);
    },
    countText() {
      if (this.count !== 1) {
        return `You have ${this.count} items in your cart`
      }
      return 'You have 1 item in your cart'
    }
  },
  methods: {
    goTo() {
      if (this.$route.path.match('/order/checkout')) {
        this.hideCart()
      } else if (this.user) {
        if (this.user.first_name === null || this.user.last_name === null) {
          this.$router.push('/account/update')
        } else if (this.user.first_name.toLowerCase() === 'firstname' || this.user.last_name.toLowerCase() === 'lastname') {
          this.$router.push('/account/update')
        } else {
          this.$router.push('/order/checkout')
        }
      } else {
        this.$router.push('/order/checkout')
      }
    },
    hideCart() {
      document.body.style.overflow = 'auto'
      this.$store.commit('setShowCart', false)
    },
    async setHandoff(type) {
      if (type == 'delivery' && !this.location.services.dispatch && !this.location.services.delivery) {
        this.$store.commit('setErrors', ['Delivery is not available at this location.'])
      } else if (!this.location.services.pickup) {
        this.$store.commit('setErrors', ['Pickup is not available at this location.'])
      } else  {        
        if(type == 'delivery' || type == 'dispatch') {
          this.$store.commit('setSwitchHandoff', true)
          document.body.style.overflow = 'auto'
          this.$store.commit('setShowCart', false)
          this.$router.push('/')
        } else {
          await this.$api.setHandoff(type)
        }
      }
    },
    handleTimeUpdate(time) {
      console.log('handleTimeUpdate', time)
    },
  },
  watch: {
    '$route.path'(e) {
      document.body.style.overflow = 'auto'
      this.$store.commit('setShowCart', false)
    }
  }
}
