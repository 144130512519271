import { render, staticRenderFns } from "./MobileNav.vue?vue&type=template&id=505786aa&scoped=true&"
import script from "./MobileNav.vue?vue&type=script&lang=js&"
export * from "./MobileNav.vue?vue&type=script&lang=js&"
import style0 from "./MobileNav.vue?vue&type=style&index=0&id=505786aa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "505786aa",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconUser: require('/opt/build/repo/components/icons/User.vue').default})
