//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
import { mapState } from 'vuex'

export default {
  props: {
    onSelect: {
      type: Function,
    },
    lang: {
      type: String,
      default: 'Update'
    }
  },
  computed: {
    ...mapState([ 'location', 'order', 'addingProduct' ])
  },
  data() {
    return {
      selectDate: true,
      days: [],
      selectedDay: null,
      selectedTime: null,
      minTime: null,
      maxTime: null,
      invalid: false,
      later: false,
      more: false
    }
  },
  async mounted() {
    let from = moment().format('YYYYMMDD')
    let to = moment().add(this.location.services.advancedays, 'days').format('YYYYMMDD')
    let calendar = await this.$api.getCalendar(from, to)
    let method = 'default'
    if(this.order && (this.order.handoff == 'dispatch' || this.order.handoff == 'delivery')) {
      method = 'Delivery'
    } else if(this.order && this.order.handoff == 'curbside') {
      method = 'Curbside'
    }
    calendar[method].map((d, i) => {
      let day = moment(d.open, 'YYYYMMDD H:mm')
      this.days.push({
        dayOfWeek: d.day,
        dayOfMonth: day.format('D'),
        fullDate: d.earliest || d.open,
        endTime: d.close
      })
    })
  },
  methods: {
    goBack() {
      this.selectDate = true
    },
    closeModal() {
      this.$store.commit('setTimePicker', false)
    },
    async setASAP() {
      let order = null
      if(this.order) {
        order = await this.$api.deleteTime()
      } else {
        let handoff_time = null
        if(this.location.calendar && this.location.calendar.default.length > 0) {
          let testMin = moment().add(this.location.services.leadtime + 1, 'minutes')
          let absMin = moment(this.days[0].fullDate, 'YYYYMMDD HH:mm')
          let earliest = testMin >= absMin ? testMin : absMin //moment(this.days[0].fullDate, 'YYYYMMDD HH:mm')
          let latest = moment(this.days[0].endTime, 'YYYYMMDD HH:mm')
          if(moment() < earliest) {
            handoff_time = earliest.format('YYYYMMDD HH:mm') //this.days[0].fullDate
          } else if(moment() > latest) {
            handoff_time = this.days[1].fullDate
          } else {
            handoff_time = null
          }
        }
        await this.$api.getMenu(false, true, handoff_time)
        order = await this.$api.createOrder(handoff_time)
        if(order) {
          if(this.addingProduct == true) {
            this.$store.commit('setAddingProduct', false)
          } else {
            this.$router.push('/order/')
          }
        }
      }
      if(order) {
        if(this.$route.path.match(/order\/checkout/)) {
          await this.$api.validateOrder()
        }
        this.$store.commit('setTimePicker', false)
      }
    },
    async setHandoffTime() {
      let day = this.days[this.selectedDay]
      let selected = day.fullDate.replace(/(\d{1,2})\:(\d{2})$/, this.selectedTime)
      let minTime = day.fullDate.replace(/(\d{1,2})\:(\d{2})$/, this.minTime)
      let maxTime = day.fullDate.replace(/(\d{1,2})\:(\d{2})$/, this.maxTime)
      console.log(maxTime)
      if (maxTime.split(' ')[1] == '00:00') {
        maxTime = maxTime.split(' ')[0] + ' 11:59'
      }
      let isSameOrAfterMin = moment(selected,'YYYYMMDD HH:mm').isSameOrAfter(moment(minTime, 'YYYYMMDD H:mm'), 'minutes')
      let isSameOrBeforeMax = moment(selected,'YYYYMMDD HH:mm').isSameOrBefore(moment(maxTime, 'YYYYMMDD H:mm'), 'minutes')
      if((isSameOrAfterMin && isSameOrBeforeMax) || minTime == maxTime) {
        let order = null
        if(this.order) {
          order = await this.$api.setTime(selected)
        } else {
          await this.$api.getMenu(false, true, selected)
          order = await this.$api.createOrder(selected)
          if(order) {
            if(this.addingProduct == true) {
              this.$store.commit('setAddingProduct', false)
            } else {
              this.$router.push('/order/')
            }
          }
        }
        if(order) {
          if(this.$route.path.match(/order\/checkout/)) {
            await this.$api.validateOrder()
          }
          this.$store.commit('setTimePicker', false)
        }
      } else {
        this.$store.commit('setErrors', ['Select a time between '+moment(this.minTime,'H:mm').format('LT')+' and '+moment(this.maxTime,'H:mm').format('LT')+'.'])
      }
    },
    selectDay(i) {
      this.selectedDay = i
      this.selectDate = false
      let day = this.days[i]
      if(i == 0) {
        if(this.order) {
          // If today and an order already exists
          this.minTime = moment(this.order.time.earliest, 'YYYYMMDD H:mm').format('HH:mm')
        } else {
          // now plus lead time
          let testMin = moment().add(this.location.services.leadtime, 'minutes') //.format('HH:mm')
          // first available time via the calendar
          let absMin = moment(day.fullDate, 'YYYYMMDD H:mm') //.format('HH:mm')
          if(testMin >= absMin) {
            this.minTime = testMin.format('HH:mm')
          } else {
            this.minTime = absMin.format('HH:mm')
          }
        }
      } else {
        // any future day
        this.minTime = moment(day.fullDate, 'YYYYMMDD H:mm').format('HH:mm')
      }
      this.selectedTime = this.minTime
      this.maxTime = moment(day.endTime, 'YYYYMMDD H:mm').format('HH:mm')
      if (this.maxTime == '0:00') {
        this.maxTime = '23:59'
      }
    },
    setSelectedTime(time) {
      this.selectedTime = time
      let day = this.days[this.selectedDay]
      let selected = day.fullDate.replace(/(\d{1,2})\:(\d{2})$/, this.selectedTime)
      let minTime = day.fullDate.replace(/(\d{1,2})\:(\d{2})$/, this.minTime)
      let maxTime = day.fullDate.replace(/(\d{1,2})\:(\d{2})$/, this.maxTime)
      if (maxTime.split(' ')[1] == '00:00') {
        maxTime = maxTime.split(' ')[0] + ' 11:59'
      }
      let isSameOrAfterMin = moment(selected,'YYYYMMDD HH:mm').isSameOrAfter(moment(minTime, 'YYYYMMDD H:mm'), 'minutes')
      let isSameOrBeforeMax = moment(selected,'YYYYMMDD HH:mm').isSameOrBefore(moment(maxTime, 'YYYYMMDD H:mm'), 'minutes')
      if (isSameOrAfterMin && isSameOrBeforeMax) {
        this.invalid = false
      } else {
        this.invalid = true
      }
    }
  }
}
