//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
import { mapState } from 'vuex'

export default {
  data() {
    return {
      offers: null,
      apply: 'Apply',
    }
  },
  computed: {
    ...mapState([ 'order', 'options', 'user', 'location' ]),
    mobile() {
      if (this.$mq === 'sm' || this.$mq === 'md') {
        return true
      } else {
        return false
      }
    },
    orderOffer() {
      if (this.order && this.order.coupon) {
        return this.order.coupon.couponcode
      } else {
        return null
      }
    }
  },
  mounted() {
    this.getOffers()
  },
  methods: {
    async getOffers() {
      const orderOffers = await this.$api.getOffersForOrder()
      const allOffers = await this.$api.getBalance()
      let offers = []
      for (let x = 0; x < allOffers.rewards.length; x++) {
        let today = moment().startOf('day')
        let expire = moment(allOffers.rewards[x].expires)
        if (today.isBefore(expire) && allOffers.rewards[x].status === 'incomplete') {
          offers.push(allOffers.rewards[x])
        }
      }
      this.offers = offers
    },
    getExpireDate(exp) {
      if (exp) {
        return moment(exp).format('[Expires] M.d.YY')
      } else {
        return 'Expires 1.1.2023'
      }
    },
    async applyOffer(offer) {
      this.$store.commit('setLoading', true)
      let order = await this.$api.addCoupon(offer) 
      if(order) {
        this.$store.commit('setOrder', order)
      }
      this.$store.commit('setLoading', false)
    },
    async removeOffer(offer) {
      this.$store.commit('setLoading', true)
      let order = await this.$api.removeCoupon(offer) 
      if(order) {
        this.$store.commit('setOrder', order)
      }
      this.$store.commit('setLoading', false)
    }
  },
  watch: {
    order(){
      this.getOffers()
    }
  }
}
