//
//
//
//
//
//
//
//
//
//

import * as animationData from "~/assets/lottie/loader.json"

export default {
  data() {
    return {
      lottieOptions: {
        animationData: animationData.default,
      }
    }
  }
}
