//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState([ 'order', 'options', 'user' ])
  },
  async mounted() {
    // // console.log('Order: ', this.order)
    // // console.log('User: ', this.user)
    // await this.$api.validateOrder()
  },
  methods: {
    customizations(choices) {
      let custom = ''
      for (let x = 0;x < choices.length;x++) {
        if (x === choices.length - 1) {
        custom = custom.concat(choices[x].name)
        } else {
        custom = custom.concat(choices[x].name,  ', ')
        }
      }
      return custom
    },
    goLink(p){
      //hideCart();
      document.body.style.overflow = 'auto';
      this.$store.commit('setShowCart', false);
      if(`/order/product?id=${p.product_id}&cid=${p.id}`!==this.$route.fullPath){
        let a = document.createElement('a');
        let linkText = document.createTextNode("");
        a.appendChild(linkText);
        a.title = "";
        a.id="force-click-edit-cart"
        a.href = `/order/product?id=${p.product_id}&cid=${p.id}`;
        document.body.appendChild(a);
        document.getElementById('force-click-edit-cart').click();
        setTimeout(() => {
          document.getElementById('force-click-edit-cart').remove();
        }, 100);
      }
    },
    async removeItem(p) {
      await this.$api.removeFromOrder(p.id);
      if(p.name.includes("Utensils") || p.name.includes("utensils") ){
        if(document.getElementById("set-toogle-false")){
          document.getElementById("set-toogle-false").click();
        }
      }
      if(this.order.products.length == 0) {
        this.$router.push('/order')
      } else if(this.$route.path.match(/order\/checkout/)) {
        await this.$api.validateOrder()
      }
    },
    editLink(p) {
      return `/order/product?id=${p.product_id}&cid=${p.id}`
    },
    async getImage(p) {
      let product = await this.$api.getProduct(p.product_id)
      if (product) {
        // console.log(product)
      }

      return ''
    },
    async decrease(p) {
      if(p.quantity > 1) {
        let newProd = {
          id: p.id,
          quantity: p.quantity - 1,
          choices: []
        }
        p.choices.map(c => {
          newProd.choices.push({
            id: c.option_id,
            quantity: 1
          })
        })
        await this.$api.updateInOrder([newProd])
        if(this.$route.path.match(/order\/checkout/)) {
          await this.$api.validateOrder()
        }
      }
    },
    async increase(p) {
      if(p.quantity < 99) {
        let newProd = {
          id: p.id,
          quantity: p.quantity + 1,
          choices: []
        }
        p.choices.map(c => {
          newProd.choices.push({
            id: c.option_id,
            quantity: 1
          })
        })
        await this.$api.updateInOrder([newProd])
        if(this.$route.path.match(/order\/checkout/)) {
          await this.$api.validateOrder()
        }
      }
    }
  }
}
