//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState([ 'loading', 'errors' ])
  },
  async mounted() {
    try {
      await this.$recaptcha.init()
    } catch(err) {
      console.error(err)
    }
  }
}
