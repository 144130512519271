import { render, staticRenderFns } from "./Footer.vue?vue&type=template&id=345bbe0a&"
import script from "./Footer.vue?vue&type=script&lang=js&"
export * from "./Footer.vue?vue&type=script&lang=js&"
import style0 from "./Footer.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {IconInstagram: require('/opt/build/repo/components/icons/Instagram.vue').default,IconLinkedIn: require('/opt/build/repo/components/icons/LinkedIn.vue').default,IconTikTok: require('/opt/build/repo/components/icons/TikTok.vue').default,IconFacebook: require('/opt/build/repo/components/icons/Facebook.vue').default,Footer: require('/opt/build/repo/components/layout/Footer.vue').default})
