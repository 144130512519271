import { render, staticRenderFns } from "./ordering.vue?vue&type=template&id=e93c8aae&scoped=true&"
import script from "./ordering.vue?vue&type=script&lang=js&"
export * from "./ordering.vue?vue&type=script&lang=js&"
import style0 from "./ordering.vue?vue&type=style&index=0&id=e93c8aae&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e93c8aae",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TopBanner: require('/opt/build/repo/components/layout/TopBanner.vue').default,Header: require('/opt/build/repo/components/layout/Header.vue').default,Footer: require('/opt/build/repo/components/layout/Footer.vue').default,Loader: require('/opt/build/repo/components/common/Loader.vue').default,ErrorOverlay: require('/opt/build/repo/components/layout/ErrorOverlay.vue').default,Cart: require('/opt/build/repo/components/order/Cart.vue').default,DateTimePicker: require('/opt/build/repo/components/order/DateTimePicker.vue').default})
