//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState([ 'navMenus' ]),
    links() {
      return this.navMenus['main-menu'] ? this.navMenus['main-menu'] : []
    }
  },
  methods: {
    checkActive(title) {
      let url = this.$route.path.replace('/', '')
      if (url.replace('/', '') === '' && title.toLowerCase() === 'locations') {
        return 'active-page'
      } else if (url.replace('/', '') === title.toLowerCase() && title.toLowerCase() != 'locations') {
        return 'active-page'
      } else {
        return ''
      }
    }
  }
}
