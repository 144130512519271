import Vue from 'vue'

// GTM
import VueGtm from 'vue-tag-manager'

if(process.env.GTM_ID) {
  Vue.use(VueGtm, {
    gtmId: process.env.GTM_ID
  })
}

// GoogleMaps
import * as VueGoogleMaps from '@/node_modules/vue2-google-maps'
import GmapCluster from "vue2-google-maps/dist/components/cluster"

Vue.component('GmapCluster', GmapCluster)

Vue.use(VueGoogleMaps, {
  load: { key: process.env.GOOGLE_MAPS_KEY }
})

// Transitions
import Transitions from 'vue2-transitions'
Vue.use(Transitions)

// Forms
import VueFormulate from '@braid/vue-formulate'
import VueFormulateExtended from 'vue-formulate-extended'
Vue.use(VueFormulate, {
  plugins: [
    VueFormulateExtended({
      features: {
        textMask: true
      }
    })
  ]
})

// VueMq
import VueMq from 'vue-mq'
Vue.use(VueMq, {
  breakpoints: { 
    sm: 600,
    md: 900,
    lg: 1200,
    xl: Infinity
  },
  defaultBreakpoint: 'sm'
})

import VueCompositionApi from '@vue/composition-api'
Vue.use(VueCompositionApi)

import VueRichTextRenderer from '@marvr/storyblok-rich-text-vue-renderer'
Vue.use(VueRichTextRenderer)

import VueMarkdownPlus from 'vue-markdown-plus'
Vue.component('markdown', VueMarkdownPlus)

import VueMask from 'v-mask'
Vue.use(VueMask);