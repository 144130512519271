import { render, staticRenderFns } from "./transfer.vue?vue&type=template&id=766b3332&"
import script from "./transfer.vue?vue&type=script&lang=js&"
export * from "./transfer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Footer: require('/opt/build/repo/components/layout/Footer.vue').default,Loader: require('/opt/build/repo/components/common/Loader.vue').default,ErrorOverlay: require('/opt/build/repo/components/layout/ErrorOverlay.vue').default,Cart: require('/opt/build/repo/components/order/Cart.vue').default,DateTimePicker: require('/opt/build/repo/components/order/DateTimePicker.vue').default})
