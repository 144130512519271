/* DO NOT EDIT OUTSIDE OF MASTER */

import moment from 'moment'

export const state = () => ({
  client: 'web',
  deviceId: null,
  env: null,
  loading: false,
  errors: [],
  location: null,
  locationList: null,
  allLocations: [],
  geolocation: [],
  filters: [],
  defaultMenu: null,
  activeCategory: null,
  menu: null,
  order: null,
  orderHistory: [],
  showCart: false,
  user: null,
  token: null,
  favelocations: [],
  redirect: null,
  presubmitting: false,
  submitting: false,
  navMenus: {},
  options: {},
  icons: {},
  promo: {},
  showNav: false,
  savedPayments: false,
  accounts: [],
  selectedAccount: null,
  giftcards: [], // All saved giftcards that return after order validation
  selectedGiftcards: null, // User selected saved giftcards
  giftcard: null, // User manually entered gift cards
  supports: null,
  timePicker: false,
  asap: true,
  selectedDay: null,
  selectedTime: null,
  deliveryDays: null,
  switchHandoff: false,
  cachingBasket: false,
  scrollTop: 0,
  utensilCategoryList: [
    'Starters',
    'Salads & Bowls',
    'Pizzas & Paninis',
    'Burgers & Sandwiches',
    'Entrées',
    'Desserts',
    'Meal Prep',
    'Kids',
    'Brunch (Weekends Only)',
    'Brunch',
  ],
  upsellImages: [],
  fixedHeader: 'fixed',
  selectedMenu: 'menu',
  applyDefaultTip: true,
  topBanner: null
})

export const mutations = {
  setClient(state, str) {
    state.client = str
  },

  setDeviceId(state, str) {
    state.deviceId = str
  },

  setEnv(state, str) {
    state.env = str
  },

  setLoading(state, bool) {
    state.loading = bool
  },

  setErrors(state, arr) {
    state.errors = arr
  },

  setLocation(state, obj) {
    if (obj !== null) {
      obj.expires = moment().add(7, 'days').format('LLLL')
      state.location = obj
      localStorage.setItem(`${state.options.appPrefix}_loc`, JSON.stringify(obj))
      return
    }
    
    state.location = obj
  },

  setLocationList(state, arr) {
    state.locationList = arr
  },

  setAllLocations(state, arr) {
    state.allLocations = arr
  },

  setGeolocation(state, arr) {
    state.geolocation = arr
  },

  setFilters(state, arr) {
    state.filters = arr
  },

  setDefaultMenu(state, obj) {
    state.defaultMenu = obj
  },

  setMenu(state, obj) {
    if(obj == null) {
      localStorage.removeItem(`${state.options.appPrefix}_menu`)
    } else {
      obj.expires = moment().add(1, 'days').format('LLLL')
      localStorage.setItem(`${state.options.appPrefix}_menu`, JSON.stringify(obj))
    }
    state.menu = obj
  },

  setActiveCategory(state, obj) {
    state.activeCategory = obj
  },

  setOrder(state, obj) {
    if(obj == null) {
      localStorage.removeItem(`${state.options.appPrefix}_order`)
    } else {
      obj.expires = moment().add(1, 'days').format('LLLL')
      if (!localStorage.getItem(`site_load`)) {
        obj.reset = moment().add(30, 'minutes').format('LLLL')
      }
      localStorage.setItem(`${state.options.appPrefix}_order`, JSON.stringify(obj))
    }
    state.order = obj
  },

  setOrderHistory(state, arr) {
    state.orderHistory = arr
  }, 
  
  setHandoff(state, str) {
    state.order.handoff = str
  },

  setSwitchHandoff(state, bool) {
    state.switchHandoff = bool
  },

  setPreSubmitting(state, bool) {
    state.presubmitting = bool
  },

  setSubmitting(state, bool) {
    console.log('setSubmitting', bool)
    state.submitting = bool
  },

  setShowCart(state, bool) {
    state.showCart = bool
  },

  setUser(state, obj) {
    if(obj == null) {
      localStorage.removeItem(`${state.options.appPrefix}_user`)
    } else {
      localStorage.setItem(`${state.options.appPrefix}_user`, JSON.stringify(obj))
    }
    state.user = obj
  },

  setToken(state, obj) {
    if (obj == null) {
      localStorage.removeItem(`${state.options.appPrefix}_tok`)
    } else {
      localStorage.setItem(`${state.options.appPrefix}_tok`, JSON.stringify(obj))
    }
    state.token = obj
  },
  
  setFavelocations(state, arr) {
    state.favelocations = arr
  },

  setRedirect(state, str) {
    state.redirect = str
  },

  setNavMenus(state, obj) {
    state.navMenus = obj
  },

  setPromo(state, obj) {
    state.promo = obj
  },

  setOptions(state, obj) {
    state.options = obj
  },

  setIcons(state, obj) {
    state.icons = obj
  },

  setShowNav(state, bool) {
    state.showNav = bool
  },

  setSavedPayments(state, bool) {
    state.savedPayments = bool
  },

  setAccounts(state, arr) {
    state.accounts = arr
  },

  setGiftcards(state, arr) {
    state.giftcards = arr
  },

  setSelectedAccount(state, str) {
    state.selectedAccount = str
  },

  setSelectedGiftcards(state, arr) {
    state.selectedGiftcards = arr
  },
  
  setSupports(state, obj) {
    state.supports = obj
  },

  setGiftcard(state, obj) {
    state.giftcard = obj
  },

  setTimePicker(state, bool) {
    console.log('setTimePicker', bool)
    
    state.timePicker = bool
  },

  setAsap(state, bool) {
    state.asap = bool
  },

  setSelectedDay(state, num) {
    state.selectedDay = num
  },

  setSelectedTime(state, str) {
    state.selectedTime = str
  },

  setDeliveryDays(state, arr) {
    state.deliveryDays = arr
  },

  setCachingBasket(state, bool) {
    state.cachingBasket = bool
  },

  setScrollTop(state, val) {
    state.scrollTop = val
  },

  setUpsellImages(state, arr) {
    // removes storyblok extra fields
    const upsells = arr.reduce((acc, curr) => {
      acc.push ({ id: Number(curr.id), name: curr.name, image: curr.image })

      return acc
    }, [])

    state.upsellImages = upsells
  },

  setHeaderPosition(state, bool) {
    state.fixedHeader = bool
  },

  setSelectedMenu(state, val) {
    state.selectedMenu = val
  },

  setApplyDefaultTip(state, bool) {
    state.applyDefaultTip = bool
  },

  setTopBanner(state, data) {
    state.topBanner = data
  }
}

export const actions = {
  async nuxtServerInit({ commit }, context ) {
    let globals = await context.$storyblok.getStories('_globals')

    let menus = {}
    globals.map(g => {
      if(g.content.component == 'menu') {
        menus[g.slug] = g.content.links
      } else if(g.content.component == 'mobile-menu') {
        menus[g.slug] = g.content.links
      } else if(g.content.component == 'footer-menu-column-one') {
        menus[g.slug] = g.content.links
      } else if(g.content.component == 'footer-menu-column-two') {
        menus[g.slug] = g.content.links
      } else if(g.content.component == 'footer-menu-column-three') {
        menus[g.slug] = g.content.links
      } else if(g.content.component == 'global') {
        let options = {}
        g.content.options.map(o => {
          options[o.key] = o.value == 'yes' ? true : o.value == 'no' ? false : o.value
        })
        commit('setOptions', options)
      } else if(g.content.component == 'icons') {
        let icons = {
          logo: g.content.logo,
          appicon: g.content.appicon
        }
        commit('setIcons', icons)
      } else if(g.content.component == 'location_list') {
        commit('setLocationList', g.content.locations)
        commit('setAllLocations', g.content.locations)
      } else if(g.content.component == 'product_list') {
        commit('setDefaultMenu', g.content)
      } else if(g.content.component == 'page' && g.slug == 'upsell-images') {
        const { body = [] } = g.content.columns[0]
        commit('setUpsellImages', body)
      } else if (g.content.component === 'Banner' && g.slug === 'top-banner') {
        commit('setTopBanner', g.content)
      }
    })
    commit('setNavMenus', menus)
  },
  nuxtClientInit({ commit }, context) {
    const p = this.state.options.appPrefix
    localStorage.setItem(`site_load`, true)
    if(localStorage.getItem(`${p}_loc`)) {
      commit('setLocation', JSON.parse(localStorage.getItem(`${p}_loc`)))
    }
    if(localStorage.getItem(`${p}_menu`)) {
      commit('setMenu', JSON.parse(localStorage.getItem(`${p}_menu`)))
    }
    if(localStorage.getItem(`${p}_order`)) {
      commit('setOrder', JSON.parse(localStorage.getItem(`${p}_order`)))
    }
    if(localStorage.getItem(`${p}_user`)) {
      commit('setUser', JSON.parse(localStorage.getItem(`${p}_user`)))
      commit('customer/setCustomerType', 'user')
    }
    if(localStorage.getItem(`${p}_tok`)) {
      commit('setToken', JSON.parse(localStorage.getItem(`${p}_tok`)))
    }
    localStorage.removeItem(`site_load`)
  },
  async locationSearch({}, data) {
    let locs = await this.$api.findLocations(data)
    if (locs) {
    //   console.log(locs)
    }
  },
  async accountSignin({ commit }, data) {
    const payload = JSON.parse(JSON.stringify(data))
    if (this.state?.order?.id) {
      payload.order_id = this.state.order.id
    }

    const token = await this.$api.loginUser(payload)
    if(token) {
      const user = await this.$api.getUser()
      if(user) {
        commit('customer/setCustomerType', 'user')
        if (this.state.order && this.state.order.products && this.state.order.products.length > 0) {
          this.$router.push('/order/checkout')
        } else if (this.state.order && this.state.order.products && this.state.order.products.length === 0) {
          this.$router.push('/order')
        } else {
          this.$router.push('/account')
        }
      }
    }
  },
  async accountSigninApp({}, data) {
    const payload = JSON.parse(JSON.stringify(data))
    if (this.state?.order?.id) {
      payload.order_id = this.state.order.id
    }

    const token = await this.$api.loginUser(data)
    if(token) {
      let stringifyToken = JSON.stringify(token)
      console.log(stringifyToken)
      if(this.state.client == 'ios') {
        console.log('postMessage ios')
        let postMessage = window.parent.postMessage
        if(window.ReactNativeWebView) {
          postMessage = window.ReactNativeWebView.postMessage
        }
        postMessage(stringifyToken)
      } else if(this.state.client == 'android') {
        console.log('postMessage android')
        window.webView.postMessage(stringifyToken)
      }
    }
  },
  async accountSignup({ commit }, data) {
    data.phone = data.phone.replace(/[\(\)\-\s]/g, '')
    const newUser = await this.$api.createUser(data)
    if(newUser) {
      const user = await this.$api.getUser()
      if(user) {
        commit('customer/setCustomerType', 'user')
        // if (this.state.order && this.state.order.products && this.state.order.products.length > 0) {
        //   this.$router.push('/order/checkout')
        // } else if (this.state.order && this.state.order.products && this.state.order.products.length === 0) {
        //   this.$router.push('/order')
        // } else {
        //   this.$router.push('/account')
        // }

        this.$router.push('/account')
      }
    }
  },
  async accountUpdate({ commit }, data) {
    data.phone = data.phone.replace(/[\(\)\-\s]/g, '')
    const update = await this.$api.updateUser(data)
    if(update) {
      if(update.tokens) {
        commit('setToken', update.tokens)
      }
      this.$router.push('/account')
    }
  },
  async accountSignout({ commit }) {
    commit('setUser', null)
    commit('setOrder', null)
    commit('customer/setCustomer', {
      first_name: null,
      last_name: null,
      email: null,
      phone: null
    },)
    commit('setToken', null)
    commit('setFavelocations', null)
    commit('customer/setCustomerType', null)
    commit('setGiftcards', [])
    commit('setSelectedGiftcards', null)
    this.$router.push(this.state.options.signinPage)
  },
  async forgotPassword({}, data) {
    await this.$api.forgotPassword(data.email)
    return true
  },
  updateCustomer({ commit }, data) {
    commit('customer/setCustomer', data)
  },
  updateDelivery({ commit }, data) {
    commit('customer/setDeliveryAddress', data)
  }
}